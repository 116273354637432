class Tabs {
    constructor(selector) {
        this.selector = selector;
        this.tabs = this.selector.querySelectorAll('[data-element="single"]');

        if (this.tabs !== null) {
            this.tabs.forEach(item => {
                let postId = item.getAttribute('data-id');
                let button = item.querySelector('[data-element="button"]');
                let content = item.querySelector('[data-element="content"]');
                if (postId !== null) {
                    this._getFromAPI(postId).then(response => {
                        if(response.length > 0){
                            // console.log(response);
                            this._generateOffer(response, content);
                        } else {
                            this._noResults();
                        }
                    }).catch(error => {
                        this._handleError(error);
                    })
                }
                if (button !== null) {
                    this._buttonEvent = this._buttonEvent.bind(this);
                    button.addEventListener('click', this._buttonEvent);
                }
            });
        }
    }
    _getFromAPI(postId){
        let url = '/wp-json/wp/v2/offer?per_page=1&include[]=' + postId;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
    _generateOffer(post, container) {
        container.innerHTML = post[0].content.rendered;
    }
    _noResults(){
        // Nie znaleziono contentu
    }
    _handleError(error){
        // Error
    }
    _buttonEvent(e) {
        e.preventDefault();

        let parent = e.target.closest('[data-element="single"]');
        if (parent !== null) {
            if (parent.classList.contains('tabs__single--active')) {
                parent.classList.remove('tabs__single--active');
                parent.querySelector('[data-element="button"]').innerText = 'Więcej';
            } else {
                parent.classList.add('tabs__single--active');
                parent.querySelector('[data-element="button"]').innerText = 'Zwiń';
            }
        }
    }
}
export default Tabs;