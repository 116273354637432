import * as basicLightbox from "basiclightbox";

class MovieModal {
    constructor(selector) {
        this.selector = selector;
        this.movieId = this.selector.getAttribute('data-movie-id');
        this.eventId = this.selector.getAttribute('data-event-id');
        this.categories = this.selector.getAttribute('data-categories');
        this.duration = this.selector.getAttribute('data-duration');
        this.age = this.selector.getAttribute('data-age');
        this.production = this.selector.getAttribute('data-production');
        this.yearProduction = this.selector.getAttribute('data-year-production');
        this.scenario = this.selector.getAttribute('data-scenario');
        this.direction = this.selector.getAttribute('data-direction');
        this.thumbnail = this.selector.getAttribute('data-thumbnail');
        this.permalink = this.selector.getAttribute('data-permalink');
        this.title = this.selector.getAttribute('data-title');
        this.seanceDate = this.selector.getAttribute('data-seance-date');
        this.type = this.selector.getAttribute('data-type');
        this.presale = this.selector.getAttribute('data-presale');
        this.disable = this.selector.getAttribute('data-disabled');

        this._clickEvent = this._clickEvent.bind(this);
        this.selector.addEventListener('click', this._clickEvent);
    }
    _clickEvent(e) {
        e.preventDefault();
        this._showModal();
    }
    _showModal() {
        const div = document.createElement('div');
        const modal = document.createElement('div');
        const close = document.createElement('a');
        close.classList.add('modal__close');
        close.innerHTML = '×';

        modal.classList.add('modal');
        modal.classList.add('modal--movie')
        modal.setAttribute('data-element', 'modal');

        const content = document.createElement('div');
        content.classList.add('modal__content');

        let thumbnail_cntr = document.createElement('div');
        thumbnail_cntr.classList.add('modal__thumbnail-cntr');

        let data_cntr = document.createElement('div');
        data_cntr.classList.add('modal__data-cntr');

        let buttons_cntr = document.createElement('div');
        buttons_cntr.classList.add('modal__buttons-cntr');

        let thumbnail = document.createElement('img');
        thumbnail.setAttribute('src', this.thumbnail);
        thumbnail.setAttribute('alt', this.title);

        let title = document.createElement('h3');
        title.innerHTML = this.title + ' (' + this.type + ')';

        let categories = document.createElement('div');
        categories.classList.add('modal__movie-cats');
        categories.innerText = this.categories;

        let duration_cntr = document.createElement('div');
        duration_cntr.classList.add('modal__movie-duration');

        let duration_b = document.createElement('b');
        duration_b.innerText = 'Czas trwania: ';

        let duration_span = document.createElement('span');
        duration_span.innerText = this.duration;

        duration_cntr.appendChild(duration_b);
        duration_cntr.appendChild(duration_span);

        let direction_cntr = document.createElement('div');
        direction_cntr.classList.add('modal__movie-direction');

        let direction_b = document.createElement('b');
        direction_b.innerText = 'Reżyseria: ';

        let direction_span = document.createElement('span');
        direction_span.innerText = this.direction;

        direction_cntr.appendChild(direction_b);
        direction_cntr.appendChild(direction_span);

        let scenario_cntr = document.createElement('div');
        scenario_cntr.classList.add('modal__movie-scenario');

        let scenario_b = document.createElement('b');
        scenario_b.innerText = 'Scenariusz: ';

        let scenario_span = document.createElement('span');
        scenario_span.innerText = this.scenario;

        scenario_cntr.appendChild(scenario_b);
        scenario_cntr.appendChild(scenario_span);

        let seance_text = document.createElement('div');
        seance_text.classList.add('modal__seance-text');
        seance_text.innerText = 'Seans: ' + this.seanceDate;

        let button_book = document.createElement('a');
        button_book.classList.add('button');
        button_book.innerText = 'Rezerwuj';
        button_book.setAttribute('href', 'http://jaroslaw.kinonabiegunach.pl/MSI/Default.aspx?event_id=' + this.eventId + '&typetran=1&returnlink=' + this.permalink);
 
        let button_buy = document.createElement('a');
        button_buy.classList.add('button', 'button--orange');
        button_buy.innerText = 'Kup bilet';
        button_buy.setAttribute('href', 'http://jaroslaw.kinonabiegunach.pl/MSI/Default.aspx?event_id=' + this.eventId + '&typetran=0&returnlink=' + this.permalink);

        thumbnail_cntr.appendChild(thumbnail);

        data_cntr.appendChild(title);
        data_cntr.appendChild(categories);
        data_cntr.appendChild(duration_cntr);
        data_cntr.appendChild(direction_cntr);
        data_cntr.appendChild(scenario_cntr);
        data_cntr.appendChild(seance_text);

        buttons_cntr.appendChild(seance_text);
        if (this.presale !== '1' && this.disable === '0') {
            buttons_cntr.appendChild(button_book);
        }
        if(this.disable === '0') buttons_cntr.appendChild(button_buy);

        content.appendChild(thumbnail_cntr);
        content.appendChild(data_cntr);
        content.appendChild(buttons_cntr);

        modal.appendChild(close);
        modal.appendChild(content);
        div.appendChild(modal);

        let instance = basicLightbox.create(div);
        instance.show();

        let parent = instance.element();
        if (parent !== null) {
            parent.classList.add('basicLightbox--movie');
        }

        close.addEventListener('click', (e) => {
            instance.close();
            instance.innerHTML = '';
        });
    }
}
export default MovieModal;