import Splide from "@splidejs/splide";
import GLightbox from "glightbox";
import {initMap} from '@netivo/base-scripts';
import Header from "./header";
import Tabs from "./tabs";
import Movies from "./movies";
import YoutubePlayer from "./youtubePlayer";
import Repertory from "./repertory";
import MovieModal from "./movieModal";

function toggleMenu(){
    let menu = document.querySelector('#mobile-menu');
    let mask = document.querySelector('#mask');
    let wrapper = document.querySelector('#wrapper');
    let content = document.querySelector('#content');
    document.querySelector('.js-hamburger').classList.toggle("active");
    // document.querySelector('#mask').toggle();
    menu.classList.toggle("toggled");
    // mask.classList.toggle('shown');
    content.classList.toggle("toggled");
    if(menu.classList.contains("toggled")) {
        let header = document.querySelector('.js-header');
        let header_height = 0;
        if (header !== null) {
            header_height = header.offsetHeight;
            console.log(header_height);
        }
        // wrapper.css('height', menu.height() + 'px');
        document.body.style.overflow = 'hidden';
        document.querySelector('html').style.overflow = 'hidden';
        menu.style.height = 'calc(100vh - ' + header_height + 'px)';
    }
    else {
        // wrapper.css('height', 'auto');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
}

let mask = document.querySelector('#mask');
if (mask !== null) {
    let menu = document.querySelector('#mobile-menu');
    mask.addEventListener('click', (e) => {
        mask.classList.toggle('shown');
        menu.classList.toggle('toggled');
        if(!menu.classList.contains("toggled")) {
            document.body.style.overflow = 'auto';
            document.querySelector('html').style.overflow = 'auto';
        }
    });
}

let hamburger = document.querySelector('.js-hamburger');
if(hamburger !== null){
    hamburger.addEventListener('click', event => {
        event.preventDefault();
        toggleMenu();
    });
}

let mobile_close = document.querySelector('.js-mobile-close');
if (mobile_close != null) {
    mobile_close.addEventListener('click', function(e) {
        let menu = document.querySelector('#mobile-menu');
        if (menu != null && menu.classList.contains('toggled')) {
            let mask = document.querySelector('#mask');

            e.preventDefault();
            document.body.style.overflow = 'initial';
            document.querySelector('html').style.overflow = 'initial';
            menu.classList.remove('toggled');
            mask.classList.remove('shown');
            document.querySelector('.js-hamburger').classList.toggle("active");
        }
    });
}

window.addEventListener('DOMContentLoaded', () => {
    window.initMap = initMap;

    let header = document.querySelector('.js-header');
    if (header !== null) {
        new Header(header);
    }

    let menu = document.querySelector('#mobile-menu');
    let header_height = 0;
    if (header !== null) {
        header_height = header.offsetHeight;
        header_height += parseInt(window.getComputedStyle(header).getPropertyValue('padding-top'));
        header_height += parseInt(window.getComputedStyle(header).getPropertyValue('padding-bottom'));
        menu.style.height = 'calc(100vh - ' + header_height + 'px)';
        menu.style.maxHeight = 'calc(100vh - ' + header_height + 'px)';
    }

    let slider = document.querySelector('.js-slider');
    if (slider !== null) {
        let ssp = new Splide(slider, {
            perPage: 1,
            rewind: true,
            pauseOnHover: true,
            dots: false,
            arrows: false,
            lazyLoad: 'nearby',
            autoplay: 5000
        });
        ssp.mount();
    }

    let tabs = document.querySelector('.js-tabs');
    if (tabs !== null) {
        new Tabs(tabs);

        let current_href = window.location.href;
        if (current_href.includes('#')) {
            let element_id = current_href.substring(current_href.indexOf('#') + 1);
            if (element_id !== '') {
                let tab = tabs.querySelector('[id="' + element_id + '"]');
                if (tab !== null) {
                    if (!tab.classList.contains('tabs__single--active')) {
                        let header_height = (document.querySelector('.js-header').offsetHeight);
                        window.scrollTo({
                            top: (tab.getBoundingClientRect().top - parseInt(header_height))
                        });

                        setTimeout(() => {
                            tab.classList.add('tabs__single--active');
                            if(navigator.userAgent.indexOf("Firefox") != -1){
                                window.scrollTo({
                                    top: (tab.getBoundingClientRect().top - parseInt(header_height))
                                });
                            }
                        }, 1000);
                    }
                }
            }
        }
    }

    let movies_grids = document.querySelectorAll('.js-movies');
    if (movies_grids !== null) {
        movies_grids.forEach(grid => {
            new Movies(grid); 
        });
    }

    let videos = document.querySelectorAll('.js-video');
    if (videos !== null) {
        videos.forEach(video => {
            new YoutubePlayer(video);
        });
    }

    let galleries = document.querySelectorAll('.js-gallery');
    if (galleries !== null) {
        galleries.forEach(gallery => {
            gallery.addEventListener('click', e => {
                e.preventDefault();

                // console.log(window.movie_gallery);

                let movie_gallery = GLightbox({
                    elements: window.movie_gallery
                }).open();
            });
        });
    }

    let repertories = document.querySelectorAll('.js-repertory');
    if (repertories !== null) {
        repertories.forEach(repertory => {
            new Repertory(repertory);
        });
    }

    let movie_modals = document.querySelectorAll('.js-movie-modal');
    if (movie_modals !== null) {
        movie_modals.forEach(modal => {
            new MovieModal(modal);
        });
    }
});